<template>
  <div>
    <div class="pb-3 pt-3">
      <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">Lifestyle</h3>
    </div>

    <b-row>
      <b-col md="12">
        <b-form-group label="Do you participate in any hazardous pursuits?">
          <b-form-input v-model="hazardousPursuit" type="text"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Have you lived or travelled abroad for more than 1 month in the last 5 years or do you intend to in the future?"
        >
          <b-form-input v-model="travelledAbroad" type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="Additional Information">
          <b-form-textarea v-model="additionalInformation" type="text" />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="pb-3 pt-3">
      <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">Medical History 1</h3>
    </div>

    <b-row class="mb-4">
      <b-col sm="6">
        <template v-for="(item, index) in medicalHistoryOneLeft">
          <medical-condition-item
            @change="onFormChange"
            v-model="medicalHistoryOneLeft[index].data"
            :label="item.label"
            :key="index"
          />
        </template>
      </b-col>
      <b-col sm="6">
        <template v-for="(item, index) in medicalHistoryOneRight">
          <medical-condition-item
            @change="onFormChange"
            v-model="medicalHistoryOneRight[index].data"
            :label="item.label"
            :key="index"
          />
        </template>
      </b-col>
    </b-row>

    <div class="pb-3 pt-3">
      <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">Medical History 2</h3>
    </div>

    <b-row class="mb-4">
      <b-col sm="6">
        <template v-for="(item, index) in medicalHistoryTwoLeft">
          <medical-condition-item
            @change="onFormChange"
            v-model="medicalHistoryTwoLeft[index].data"
            :label="item.label"
            :key="index"
          />
        </template>
      </b-col>
      <b-col sm="6">
        <template v-for="(item, index) in medicalHistoryTwoRight">
          <medical-condition-item
            @change="onFormChange"
            v-model="medicalHistoryTwoRight[index].data"
            :label="item.label"
            :key="index"
          />
        </template>
      </b-col>
    </b-row>

    <div class="pb-3 pt-3">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <b-button class="large-button" variant="primary" @click="bankDetails" v-bind:style="$store.getters.primaryButtonBox">Next</b-button>
        </b-col>
      </b-row>
    </div>
    
  </div>
</template>

<script>
import MedicalConditionItem from "@/view/pages/protection/quote-application/MedicalConditionItem.vue";

export default {
  components: {
    MedicalConditionItem,
  },
  name: "medical-questions",
  data() {
    return {
      medicalHistoryOneLeft: [
        {
          label: "Heart Disease",
          key: "heartDisease",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Stroke",
          key: "stroke",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Diabetes",
          key: "diabetes",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
      ],
      medicalHistoryOneRight: [
        {
          label: "Cancer",
          key: "cancer",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Multiple Sclerosis",
          key: "multipleSclerosis",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Depression",
          key: "depression",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        }
      ],
      medicalHistoryTwoLeft: [
        {
          label: "Stress/Anxiety",
          key: "stressAnxiety",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "High Blood Pressure",
          key: "highBloodPressure",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "High Cholesterol",
          key: "highCholesterol",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Arthritis",
          key: "arthritis",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Fits/Fainting/Blackouts",
          key: "blackouts",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Digestive Disorders",
          key: "digestiveDisorders",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Kidney/Bladder",
          key: "kidneyBladder",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Fatigue/Tiredness",
          key: "fatigueTiredness",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Visual Disturbance",
          key: "visualDisturbance",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Had an STD",
          key: "std",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "HIV",
          key: "hiv",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Gynaecological Disorder",
          key: "gynaecologicalDisorder",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        }
      ],
      medicalHistoryTwoRight: [
        {
          label: "Joint Problems",
          key: "jointProblems",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Blood Disorders",
          key: "bloodDisorders",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Hearing Problems",
          key: "hearingProblems",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Gout",
          key: "gout",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Skin Disorders",
          key: "skinDisorders",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Xrays or Scans",
          key: "xraysScans",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Any Investigations",
          key: "anyInvestigations",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Surgery in 5 yrs",
          key: "surgery",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "2 weeks abscence +",
          key: "twoWeekAbscence",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "On any medication",
          key: "onMedication",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Parents ill before 60",
          key: "parentsIll",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        },
        {
          label: "Siblings ill before 60",
          key: "siblingsIll",
          data: {
            isSelected: false,
            additionalInformation: null,
          }
        }
      ]
    };
  },
  computed: {
    hazardousPursuit: {
      get(){
        return this.$store.state.protection.applicationDetails.hazardousPursuit;
      },
      set(value){
        let quoteApplicationDetails = { ...this.quoteApplicationDetails };
        quoteApplicationDetails.hazardousPursuit = value;
        this.$store.commit("updateProtectionApplicationDetails", quoteApplicationDetails);
      }
    },
    travelledAbroad: {
      get(){
        return this.$store.state.protection.applicationDetails.travelledAbroad;
      },
      set(value){
        let quoteApplicationDetails = { ...this.quoteApplicationDetails };
        quoteApplicationDetails.travelledAbroad = value;
        this.$store.commit("updateProtectionApplicationDetails", quoteApplicationDetails);
      }
    },
    additionalInformation: {
      get(){
        return this.$store.state.protection.applicationDetails.additionalInformation;
      },
      set(value){
        let quoteApplicationDetails = { ...this.quoteApplicationDetails };
        quoteApplicationDetails.additionalInformation = value;
        this.$store.commit("updateProtectionApplicationDetails", quoteApplicationDetails);
      }
    },
    quoteApplicationDetails: {
      get() {
        console.log(this.$store.state.protection.applicationDetails);
        return this.$store.state.protection.applicationDetails;
      }
    },
  },
  methods: {
    updateMedicalQuestion(){
      var medicalHistoryOne = [...this.medicalHistoryOneLeft, ...this.medicalHistoryOneRight];
      var medicalHistoryTwo = [...this.medicalHistoryTwoLeft, ...this.medicalHistoryTwoRight];

      let quoteApplicationDetails = { ...this.quoteApplicationDetails };
      quoteApplicationDetails.medicalHistoryOne = medicalHistoryOne;
      quoteApplicationDetails.medicalHistoryTwo = medicalHistoryTwo;

      this.$store.commit("updateProtectionApplicationDetails", quoteApplicationDetails);
    },
    resize(){
      this.$resizeFrame();
    },
    onFormChange(){
      this.resize();
      this.updateMedicalQuestion();
    },
    bankDetails(){
      this.$store.commit("updateProtectionApplicationStep", "confirm");
      this.$router.push({ name: 'ProtectionQuotePayment'});
    }
  }
};
</script>

<style>
.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}
</style>