<template>
  <div>
    <b-row>
      <b-col md="12">
        <start-application v-if="quoteApplicationStep == 'agreement' || quoteApplicationStep == 'confirm'" />
        <medical-questions v-if="quoteApplicationStep == 'medicalQuestions'" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StartApplication from "@/view/pages/protection/quote-application/StartApplication.vue";
import MedicalQuestions from "@/view/pages/protection/quote-application/MedicalQuestions.vue";


export default {
  name: "quote-application",
  components: {
    StartApplication,
    MedicalQuestions
  },
  computed: {
    quoteApplicationStep: {
      get() {
        return this.$store.state.protection.quoteApplicationStep;
      }
    }
  },
  mounted(){
    // Signal parent window
    var pluginMessage = { 
      messageType: "productSelected",
      payload: {}
    };
    window.parent.postMessage(JSON.stringify(pluginMessage),"*");
  }
}
</script>

<style lang="scss">
</style>