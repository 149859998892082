<template>
  <div class="mb-1">
    <p-check class="p-icon p-curve p-bigger p-smooth" name="check" color="success" v-model="isSelected" @change="handleInput">
      <i slot="extra" class="icon fa fa-check"></i>
      {{label}}
    </p-check>
    <b-form-group 
      v-if="isSelected" 
      class="mt-3" 
      label="Please provide detailed information">
      <b-form-textarea
        @change="handleInput"
        v-model="additionalInformation"
        type="text"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    value: Object, 
    label: String},
  name: "medical-condition-item",
  data() {
    return {
      isSelected: this.value.isSelected,
      additionalInformation: this.value.additionalInformation
    };
  },
  methods: {
    handleInput(){
      var data = {
        isSelected: this.isSelected,
        additionalInformation: this.additionalInformation
      }
      this.$emit('input', data);
      this.$emit('change', data);
    }
  }
};
</script>

<style>
</style>