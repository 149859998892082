<template>
  <div class="application-container">
    <div class="p-4 policy-summary" v-bind:style="$store.getters.elementColourBlockBg">
      Policy Summary
    </div>

    <b-row class="m-0 mt-5">
      <b-col class="p-0 pr-1 d-flex align-items-stretch" md="4">
        <div class="application-box d-flex flex-column align-items-center p-5 w-100 mb-2" v-bind:style="$store.getters.moreLessBorder">
          <b-img fluid :src="product.ProviderLogoAbsoluteURL" class="insurer-logo mb-4"></b-img>
          <div class="font-weight-bold">Monthly Premium</div>
          <div v-bind:style="$store.getters.writingColourBlockText" class="price-display font-weight-bold">£{{ product.Premium }}</div>
          <div class="font-weight-bold">NO FEE*</div>
        </div>
      </b-col>
      <b-col class="p-0 pl-1 pr-1 d-flex align-items-stretch" md="4">
        <div class="application-box d-flex flex-column p-5 f-11px w-100 mb-2" v-bind:style="$store.getters.moreLessBorder">
          <div>Your cover documents</div>
          <div class="mb-0">
            <b-icon icon="file-earmark-fill" class="mr-2" font-scale="1" v-bind:style="$store.getters.elementColourBlockText"></b-icon>
            <span>Summary</span>
          </div>
          <div class="mb-0">
            <b-icon icon="file-earmark-fill" class="mr-2" font-scale="1" v-bind:style="$store.getters.elementColourBlockText"></b-icon>
            <span>Key Features</span>
          </div>
        </div>
      </b-col>
      <b-col class="p-0 pl-1 d-flex align-items-stretch" md="4">
        <div class="application-box d-flex flex-column align-items-center p-5 f-11px w-100 mb-2" v-bind:style="$store.getters.moreLessBorder">
          <div class="mb-2">
            <b-form-checkbox name="has_read_quote" v-model="isInformationCorrect" :disabled="quoteApplicationStep == 'confirm'">You confirm that all questions answered and information provided in connection with this application have been answered truthfully and honestly.</b-form-checkbox>
          </div>   
          <b-button v-if="quoteApplicationStep != 'confirm'" class="w-100" @click="agreeTerms" :disabled="!isInformationCorrect">NEXT</b-button>
        </div>
      </b-col>
    </b-row>
    <div class="mt-5 f-14px return-result">
      <span v-bind:style="$store.getters.writingColourBlockText" @click="$router.push({ name: 'ProtectionQuoteResult' })">Return to results</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "start-application",
  computed: {
    product: {
      get() {
        return this.$store.state.protection.selectedProduct;
      }
    },
    isInformationCorrect: {
      get() {
        return this.quoteApplication.isInformationCorrect;
      },
      set(value) {
        let quoteApplication = { ...this.quoteApplication };
        quoteApplication.isInformationCorrect = value;
        this.$store.commit("updateProtectionQuoteApplication", quoteApplication);
      }
    },
    quoteApplication: {
      get() {
        return this.$store.state.protection.quoteApplication;
      }
    },
    quoteApplicationStep: {
      get() {
        return this.$store.state.protection.quoteApplicationStep;
      }
    },
    quotePaymentStep: {
      get() {
        return this.$store.state.protection.quotePaymentStep;
      }
    }
  },
  methods: {
    agreeTerms: function(){
      this.$store.commit("updateProtectionApplicationStep", "medicalQuestions");
      var pluginMessage = { 
        messageType: "applicationStarted",
        payload: {}
      };
      window.parent.postMessage(JSON.stringify(pluginMessage),"*");
    }
  },
  mounted: function(){
    window.parent.postMessage(JSON.stringify({messageType: "resetSize"}),"*");
  }
}
</script>

<style lang="scss">
.policy-detail-container {
  background-color: $accent;
}

.policy-agreement-container {
  background-color: $accent;
}

.policy-summary {
  border-radius: 10px;
  font-weight: 500;
}

.application-box {
  border: solid 1px;
  border-radius: 8px;
  background: #FFF;
}

.return-result {
  cursor: pointer;
}

.f-11px {
  font-size: 11px !important;
}

.f-14px {
  font-size: 14px !important;
}
</style>